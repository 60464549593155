import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["formSignIn", "formChangePassword", "formSendMailPassword"]

  signIn(event) {
    let form = this.formSignInTarget
    if (!form.checkValidity()) {
      event.preventDefault()
      event.stopPropagation()
    }

    form.classList.add('was-validated')
  }

  changePassword(event) {
    let form = this.formChangePasswordTarget
    if (!form.checkValidity()) {
      event.preventDefault()
      event.stopPropagation()
    }

    form.classList.add('was-validated')
  }

  sendMailChangePassword(event) {
    let form = this.formSendMailPasswordTarget
    if (!form.checkValidity()) {
      event.preventDefault()
      event.stopPropagation()
    }

    form.classList.add('was-validated')
  }
}