import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
const querystring = require('querystring')

export default class extends Controller {
  static values = { ajaxClientInquiryStatusesPath: String,
                    ajaxUpdateInquiryClientPlanInfoPath: String }
  static targets = ['txtStatus1', 'txtStatus2', 'txtStatusValue1', 'txtStatusValue2', 'txtMemo1', 'txtMemo2',
                    'txtInquiryClientPlanId',
                    'txtStatus1New', 'txtStatus2New', 'txtMemo1New', 'txtMemo2New',
                    'boxEditInfo']

  openEditMode() {
    this.preStatus1 = this.txtStatus1Target.innerText
    this.preStatus2 = this.txtStatus2Target.innerText
    this.preStatusValue1 = this.txtStatusValue1Target.value
    this.preStatusValue2 = this.txtStatusValue2Target.value
    this.preMemo1 = this.txtMemo1Target.innerText
    this.preMemo2 = this.txtMemo2Target.innerText

    this.loadClientInquiryStatuses()

    this.txtMemo1Target.innerHTML = this.renderTextArea('txtMemo1New', this.preMemo1)
    this.txtMemo2Target.innerHTML = this.renderTextArea('txtMemo2New', this.preMemo2)

    this.boxEditInfoTarget.innerHTML = `
    <div class="col"></div>
    <div class="col"></div>
    <div class="col btn-group px-0">
      <button name="button" type="button" data-action="click->${this.identifier}#cancelEditMode" class="btn btn-outline-primary btn-sm">キャンセル</button>
    </div>
    <div class="col btn-group">
      <button name="button" type="button" data-action="click->${this.identifier}#updateEditMode" class="btn btn-primary btn-sm">更新</button>
    </div>
    `
  }

  loadClientInquiryStatuses() {
    let _this = this
    Rails.ajax({
      type: 'GET',
      url: this.ajaxClientInquiryStatusesPathValue,
      success: function(response) {
        let clientInquiryStatuses = response['client_inquiry_statuses']
        _this.txtStatus1Target.innerHTML = _this.renderSelectBox('txtStatus1New', _this.preStatusValue1, clientInquiryStatuses)
        _this.txtStatus2Target.innerHTML = _this.renderSelectBox('txtStatus2New', _this.preStatusValue2, clientInquiryStatuses)
      }
    })
  }

  renderSelectBox(targetName, selectedValue, collections) {
    let options = ''
    collections.forEach(function (item) {
      let selected = (item[0] == selectedValue) ? 'selected' : ''
      options += `<option value="${item[0]}" ${selected}>${item[1]}</option>`
    });

    return `
    <select name="${targetName}" id="${targetName}" data-${this.identifier}-target="${targetName}" class="d-inline w-25 form-select">
    ${options}
    </select>
    `
  }

  renderTextArea(targetName, value) {
    return `
    <textarea name="${targetName}" id="${targetName}" data-${this.identifier}-target="${targetName}" class="form-control d-inline w-25">${value}</textarea>
    `
  }

  cancelEditMode() {
    this.txtStatus1Target.innerHTML = this.preStatus1
    this.txtStatus2Target.innerHTML = this.preStatus2

    this.txtMemo1Target.innerHTML = this.preMemo1
    this.txtMemo2Target.innerHTML = this.preMemo2

    this.boxEditInfoTarget.innerHTML = `
    <div class="col"></div>
    <div class="col"></div>
    <div class="col"></div>
    <div class="col btn-group">
      <button name="button" type="button" data-action="click->${this.identifier}#openEditMode" class="btn btn-outline-primary btn-sm">編集</button>
    </div>
    `
  }

  updateEditMode() {
    let data = { inquiry_client_plan_id: this.txtInquiryClientPlanIdTarget.value,
                 status_1: this.txtStatus1NewTarget.value,
                 status_2: this.txtStatus2NewTarget.value,
                 memo_1: this.txtMemo1NewTarget.value,
                 memo_2: this.txtMemo2NewTarget.value }
    let queryStr = querystring.stringify(data)

    Rails.ajax({
      type: 'PATCH',
      url: this.ajaxUpdateInquiryClientPlanInfoPathValue,
      data: queryStr,
      success: function (response) {
        window.location.reload()
      },
      error: function(errorMessages) {
        if(errorMessages['errors']) {
          window.location.reload()
        }
      }
    })
  }
}
