import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['agreeTermsCheckBox', 'btnSubmitForm']

  enabledButtonSubmit() {
    if (this.agreeTermsCheckBoxTarget.checked == true) {
      this.btnSubmitFormTarget.removeAttribute('disabled')
    } else {
      this.btnSubmitFormTarget.setAttribute('disabled', 'disabled')
    }
  }
}
