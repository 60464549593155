import { Controller } from 'stimulus'
import $ from 'jquery'
import { ajax } from '@rails/ujs'

export default class extends Controller {
  static targets = ['assessmentSettingForm', 'optionMasterCity', 'optionMasterMaker', 'optionCity', 'optionCarModel', 'optionMileage', 'optionModelYear']
  static values = { validatePath: String }
  
  initialize() {
    const FORM_INDEX = 0
    this.displayFormHandler(FORM_INDEX)
  }

  prevForm(el) {
    let prevIndex = parseInt(el.target.parentNode.parentNode.getAttribute('data-form-index')) - 1
    this.displayFormHandler(prevIndex)
  }

  nextForm(el) {
    this.selectedAllOption()
    let _this          = this
    let dataForm       = new FormData(this.assessmentSettingFormTarget)
    let selectedTarget = $(el.target.parentNode.parentNode).find('select').last()

    ajax({
      url: this.validatePathValue,
      type: 'POST',
      dataType: 'json',
      data: dataForm,
      success: function (response) {
        let errorComponent = selectedTarget[0].nextSibling
        let errorContent   = response.error_messages[errorComponent.getAttribute('data-validate-field')]

        if(errorContent && errorContent.length) {
          selectedTarget.addClass('is-invalid')          
          errorComponent.textContent = errorContent.join()
        } else {
          let nextIndex = parseInt(el.target.parentNode.parentNode.getAttribute('data-form-index')) + 1
          _this.displayFormHandler(nextIndex)
        }
      },
    })
  }

  displayFormHandler(index) {
    let hiddenStep = document.querySelectorAll(`.management-block__detail:not([data-form-index="${index}"])`)
    hiddenStep.forEach(element => { element.classList.add('inactive') })
    let targetStep = document.querySelector(`[data-form-index="${index}"]`)
    targetStep.classList.remove('inactive')
    targetStep.classList.add('active')
  }

  // Handle copy selected rows to another select_option

  copySelectedRowsToRightColumn(el) {
    let leftColumn  = el.target.parentNode.parentNode.previousSibling.lastChild
    let rightColumn = el.target.parentNode.parentNode.nextSibling.children[2]
    for (let item of leftColumn.selectedOptions) {
      if (!rightColumn.querySelectorAll(`option[value="${item.value}"]`).length) {
        rightColumn.appendChild(item.cloneNode(true))
      }
    }
    rightColumn.classList.remove('is-invalid')
  }

  copyAndRenameSelectedRowsToRightColumn(el) {
    let leftColumn  = el.target.parentNode.parentNode.previousSibling.lastChild
    let rightColumn = el.target.parentNode.parentNode.nextSibling.children[2]
    let originText  = el.target.parentNode.parentNode.parentNode.previousSibling.selectedOptions[0].text

    for (let item of leftColumn.selectedOptions) {
      if (!rightColumn.querySelectorAll(`option[value="${item.value}"]`).length) {
        let clone = item.cloneNode(true)
        let currentText = clone.text
        clone.text = `${originText} ${currentText}`
        rightColumn.appendChild(clone)
      }
    }
    rightColumn.classList.remove('is-invalid')
    this.sortOptionAscByName(rightColumn)
  }

  removeSelectedRowsFromRightColumn(el) {
    let optionsRightColumn = el.target.parentNode.parentNode.nextSibling.children[2].selectedOptions
    while (optionsRightColumn.length > 0) {
      optionsRightColumn[0].remove()
    }
  }

  sortOptionAscByName(target) {
    let opts_list = Array.prototype.slice.call(target.options);
    opts_list.sort(function (a, b) { return a.text > b.text ? 1 : -1; })
    for (let item of opts_list) { target.appendChild(item) }
  }

  // Load Data Ajax

  ajaxLoadCarModels(el) {
    let _this   = this
    let _el     = el
    let makerId = el.target.selectedOptions[0].value
    let data    = { maker_id: makerId }

    ajax({
      url: '/ajax/car_models',
      type: 'GET',
      dataType: 'json',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        $(_this.optionMasterMakerTarget).find('option').remove()
        if (response['models'].length > 0) {
          let models = response['models']
          $(_el.target.nextSibling.firstChild.firstChild.lastChild).prop('disabled', false);
          for(let item of models){
            $(_this.optionMasterMakerTarget).append(`<option value=${item[0]}>${item[1]}</option>`)
          }
        } else {
          $(_el.target.nextSibling.firstChild.firstChild.lastChild).prop('disabled', true);
        }
      }
    })
  }

  ajaxLoadCities(el) {
    let _this        = this
    let _el          = el
    let prefectureId = el.target.selectedOptions[0].value
    let data         = { prefecture_id: prefectureId }

    ajax({
      url: '/ajax/cities',
      type: 'GET',
      dataType: 'json',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        $(_this.optionMasterCityTarget).find('option').remove()
        if (response['cities'].length > 0) {
          $(_el.target.nextSibling.firstChild.firstChild.lastChild).prop('disabled', false);
          let cities = response['cities']
          for(let item of cities){
            $(_this.optionMasterCityTarget).append(`<option value=${item[0]}>${item[1]}</option>`)
          }
        } else {
          $(_el.target.nextSibling.firstChild.firstChild.lastChild).prop('disabled', true);
        }
      }
    })
  }

  // Hanlde when submit form

  selectedAllOption() {
    let actionMapper = {
      area_settings:       "_$(this.optionCityTarget).find('option').prop('selected', true)",
      car_model_settings:  "_$(this.optionCarModelTarget).find('option').prop('selected', true)",
      model_year_settings: "_$(this.optionModelYearTarget).find('option').prop('selected', true)",
      mileage_settings:    "_$(this.optionMileageTarget).find('option').prop('selected', true)"
    }
    let forms = document.querySelectorAll('.management-block__detail[data-form-step]')
    let _$ = $
    for (let form of forms) {
      try { eval(actionMapper[form.getAttribute('data-form-step')]) }
      catch(err) { console.log(err) }
    }
  }

  validateAndSubmit(el) {
    this.selectedAllOption()
    let _this          = this
    let dataForm       = new FormData(this.assessmentSettingFormTarget)
    let selectedTarget = $(el.target.parentNode.parentNode).find('select').last()

    ajax({
      url: this.validatePathValue,
      type: 'POST',
      dataType: 'json',
      data: dataForm,
      success: function (response) {
        let errorComponent = selectedTarget[0].nextSibling
        let errorContent   = response.error_messages[errorComponent.getAttribute('data-validate-field')]

        if(errorContent && errorContent.length) {
          selectedTarget.addClass('is-invalid')          
          errorComponent.textContent = errorContent.join()
        } else {
          $(_this.assessmentSettingFormTarget).submit()
        }
      },
    })
  }

  selectAllItemsLeftColumn(el) {
    $(el.target.parentNode.nextSibling).find('option').prop('selected', true)
  }

  getAllData(el) {
    let _this  = this
    let path   = el.target.getAttribute('data-path')
    let target = el.target.getAttribute('data-target')

    ajax({
      url: `/ajax/${path}`,
      type: 'GET',
      dataType: 'json',
      success: function (response) {
        let models = response['models']
        let rightColumn = $(eval(`_this.${target}Target`))
        rightColumn.find('option').remove()
        for(let item of models) {
          rightColumn.append(`<option value=${item[0]}>${item[1]}</option>`)
        }
      }
    })

  }
}
