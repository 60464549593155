import { Controller } from 'stimulus'
import { ajax } from '@rails/ujs'
import renderInlineError from 'commons/render_inline_message'

export default class extends Controller {
  static targets = ['sectionDisplayError']
  static values = { disabledEmailPath: String }

  disabledEmail(el) {
    let mailAddress  = el.target.parentNode.children[0].innerText
    let authenAction = confirm(`${mailAddress} メールを削除します。よろしいですか？`)

    if (authenAction) {
      let mailId             = el.target.getAttribute('data-mail-object-id')
      let lengthOfInput      = el.target.parentNode.parentNode.children.length
      let errorContainTarget = this.sectionDisplayErrorTarget
      let btnTestTransmissionTarget = el.target.parentNode.parentNode.nextSibling.nextSibling

      ajax({
        type: 'POST',
        url: `${this.disabledEmailPathValue}${mailId}`,
        success: function(response) {
          document.querySelector(`[data-mail-object-id="${mailId}"]`).parentNode.remove()
          renderInlineError(errorContainTarget, response.message, 'alert-success', 4000)
          if (lengthOfInput == 1) { btnTestTransmissionTarget.classList.add('d-none') }
        },
        error: function(response) {
          renderInlineError(errorContainTarget, response.error.message, 'alert-danger', 4000)
        }
      })
    }    
  }

  testTransmisstion(el) {
    let authenAction = confirm('登録されているメールアドレス宛にテスト送信します。よろしいですか？')

    if (authenAction) {
      let emailType          = el.target.getAttribute('data-email-type')
      let masterPlanId       = el.target.getAttribute('data-master-plan-id')
      let errorContainTarget = this.sectionDisplayErrorTarget

      ajax({
        type: 'GET',
        url: `/assessment_settings/${masterPlanId}/test_email_transmission_handler/${emailType}`,
        success: function(response) {
          renderInlineError(errorContainTarget, response.message, 'alert-success', 4000)
        },
        error: function(response) {
          renderInlineError(errorContainTarget, response.error.message, 'alert-danger', 4000)
        }
      })
    } 
  }

  addEmail(el) {
    let mailAddress  = el.target.parentNode.firstChild.value
    let authenAction = confirm(`${mailAddress} メールを追加します。よろしいですか？`)

    if (authenAction) {
      let form               = el.target.parentNode.parentNode
      let dataForm           = new FormData(form);
      let currentInput       = el.target.parentNode.firstChild
      let sectionCancelEmail = el.target.parentNode.parentNode.parentNode.firstChild
      let spanError          = el.target.parentNode.nextSibling
      let errorContainTarget = this.sectionDisplayErrorTarget
      let btnTestTransmissionTarget = el.target.parentNode.parentNode.nextSibling

      ajax({
        type: 'POST',
        url: form.action,
        data: dataForm,
        success: function(response) {
          let element = new DOMParser().parseFromString(response.html, 'text/html').body.childNodes
          sectionCancelEmail.append(element[0])
          currentInput.value = null
          renderInlineError(errorContainTarget, response.message, 'alert-success', 4000)
          spanError.classList.remove('d-block')
          currentInput.classList.remove('is-invalid')
          btnTestTransmissionTarget.classList.remove('d-none')
        },
        error: function(response) {
          spanError.innerText = response.error.message
          spanError.classList.add('d-block')
          currentInput.classList.add('is-invalid')
        }
      })
    }
  }

  clearError(el) {
    el.target.classList.remove('is-invalid')
    el.target.parentNode.nextSibling.classList.remove('d-block')
  }
}
