import {Controller} from "stimulus"
import {ajax} from "@rails/ujs"
import $ from "jquery"

export default class extends Controller {
  static targets = ["output", "makerId", "carModelSelectBox", "prefectureId", "citiesSelectBox"]
  static values = {loadCarModelPath: String, loadCitiesPath: String}

  loadCarModel() {
    let self    = this
    let makerId = self.makerIdTarget.value

    let data = {
      maker_id: makerId,
    }

    ajax({
      url: this.loadCarModelPathValue,
      type: 'GET',
      dataType: 'jsonp',
      callback: 'callback',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        let models = response['models']
        $(self.carModelSelectBoxTarget).find('option').remove()
        $(self.carModelSelectBoxTarget).append(`<option value="">選択してください</option>`)
        for(let item of models){
          $(self.carModelSelectBoxTarget).append(`<option value=${item[0]}>${item[1]}</option>`)
        }
      }
    })
  }

  loadCities() {
    let self    = this
    let prefectureId = self.prefectureIdTarget.value

    let data = {
      prefecture_id: prefectureId,
    }

    ajax({
      url: this.loadCitiesPathValue,
      type: 'GET',
      dataType: 'jsonp',
      callback: 'callback',
      data: new URLSearchParams(data).toString(),
      success: function (response) {
        let cities = response['cities']
        $(self.citiesSelectBoxTarget).find('option').remove()
        $(self.citiesSelectBoxTarget).append(`<option value="">選択してください</option>`)
        for(let item of cities){
          $(self.citiesSelectBoxTarget).append(`<option value=${item[0]}>${item[1]}</option>`)
        }
      }
    })
  }

}
